import React from 'react';
import PropTypes from 'prop-types';
//import { useStaticQuery, graphql } from 'gatsby';
import Icon from 'react-icons-kit';
import { disc } from 'react-icons-kit/feather/disc';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Logo from 'common/src/components/UIElements/Logo';

// Styles
// =============================================================================
import SectionAreaWrapper, {
  SectionArea,
  AwardsListBlockWrapper,
  AwardsListBlockItem,
  FooterCopyright,
} from './StaticFooter.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// Images
// =============================================================================
import LogoImage from 'common/src/assets/image/cybertonica-logo.png';

// StaticFooter
// =============================================================================
const StaticFooter = ({
  SectionWrapperStyle,
  RowStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  StaticFooterAboutStyle,
  StaticFooterLogoStyle,
  StaticFooterRegStyle,
  AwardsListTextStyle,
  footerAbout,
  dataAwards,
}) => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Box {...SectionWrapperStyle} id="static-footer">
      <Container fullWidth>
        <Box {...RowStyle}>
          <Box {...ColStyle} {...ColOneStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <AwardsListBlockWrapper>
                  {dataAwards.map((item, index) => (
                    <AwardsListBlockItem key={`benefits-list-item-${index}`}>
                      <Icon
                        icon={disc}
                        className="CT_AwardsListIcon"
                        size={20}
                      />
                      <Text content={item.content} {...AwardsListTextStyle} />
                    </AwardsListBlockItem>
                  ))}
                </AwardsListBlockWrapper>
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
          <Box {...ColStyle} {...ColTwoStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <Text content={footerAbout} {...StaticFooterAboutStyle} />
                <Logo
                  logoSrc={LogoImage}
                  title="Cybertonica Ltd."
                  logoStyle={StaticFooterLogoStyle}
                />
                <Text
                  content="Cybertonica Ltd. is a company registered in England and Wales (Company Number: 9721645)."
                  {...StaticFooterRegStyle}
                />
                <FooterCopyright>
                  Copyright Ⓒ {year} Cybertonica Ltd. All Rights Reserved.
                </FooterCopyright>
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

StaticFooter.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
  StaticFooterAboutStyle: PropTypes.object,
  StaticFooterLogoStyle: PropTypes.object,
  StaticFooterRegStyle: PropTypes.object,
  AwardsListTextStyle: PropTypes.object,
};

StaticFooter.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: brand.brand7,
    pt: ['40px', '40px', '40px', '40px', '40px'],
    pb: ['40px', '40px', '40px', '40px', '40px'],
  },
  RowStyle: {
    as: 'footer',
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'row-reverse',
    alignItems: 'top',
    justifyContent: 'center',
  },
  ColStyle: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'top',
    justifyContent: 'center',
  },
  ColOneStyle: {},
  ColTwoStyle: {},
  StaticFooterAboutStyle: {
    color: '#fff',
    fontSize: '16px',
    mb: '10px',
    textAlign: 'left',
    lineHeight: '1.4',
  },
  StaticFooterLogoStyle: {
    width: '240px',
    m: '20px 0 10px 0',
  },
  StaticFooterRegStyle: {
    color: brand.brand8Tint70,
    fontSize: '14px',
    mb: '5px',
  },
  AwardsListTextStyle: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    lineHeight: '1.4',
    color: '#fff',
    mb: '0',
    mt: '0',
  },
};

export default StaticFooter;
