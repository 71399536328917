import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import '../../../../common/src/assets/fonts/EuclidFlex-Light/style.css';
import '../../../../common/src/assets/fonts/EuclidFlex-Regular/style.css';
import '../../../../common/src/assets/fonts/EuclidFlex-Medium/style.css';
import '../../../../common/src/assets/fonts/EuclidFlex-Bold/style.css';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Euclid Flex', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Euclid Flex', sans-serif;
  }

  section {
    position: relative;
  }

  ::selection {
    color: ${themeGet('brand.brand8')};
    background: ${themeGet('brand.brand3')};
  }

  .DefaultPrivacyLink {
    color: ${themeGet('brand.brand1')};
    padding-left: 5px;
  }

  .video-modal{
    background: transparent !important;
    border: 0!important;
    .innerRndComponent{
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  .reuseModalOverlay,
  .reuseModalParentWrapper{
    z-index: 99999!important;
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
`;
