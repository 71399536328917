import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionAreaWrapper = styled.div`
  padding: 20px 0;
  //border: 1px solid ${themeGet('brand.brand1')};

  @media (min-width: 320px) {
  }
  @media (min-width: 576px) {
    padding: 20px;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 991px) {
    padding: 0 20px;
  }
  @media (min-width: 1220px) {
    padding: 0 20px;
  }
`;

export const SectionArea = styled.div`
  position: relative;
  //border: 1px solid ${themeGet('brand.brand6Tint30')};

  /* @media (min-width: 320px) {
    width: 100%;
  }
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 991px) {
    width: 100%;
  }
  @media (min-width: 1220px) {
    width: 100%;
  } */
`;

export const AwardsListBlockWrapper = styled.div``;

export const AwardsListBlockItem = styled.div`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .CT_AwardsListIcon {
    color: #fff;
    margin-right: 15px;
  }
`;

export const FooterCopyright = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
  color: ${themeGet('brand.brand8Tint70')};
`;

export default SectionAreaWrapper;
